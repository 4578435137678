<template>
  <div>
    <my-nav-bar
      title="盘点计划"
      left-text="返回"
      left-arrow
      right-text="历史"
      @click-right="toHistoryView"
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list v-model="loading" class="list" :finished="finished" @load="getList">
        <div v-for="(plan, index) in list" :key="index" class="goods-container" @click="toGoodsWarehouseDetailView(plan.goods_id, plan.id)">
          <van-cell title="计划日期" :value="plan.created_at.substring(0, 10)" />
          <van-cell v-if="plan.is_after" title-class="tips" title="提示：此商品前天有【退货】，需盘点一次" />
          <van-cell v-if="plan.is_order" title-class="tips" title="提示：此商品前天有【出货】，需盘点一次" />
          <div class="goods">
            <div>
              <van-image :src="plan.goods.product.image" class="goods-image" />
            </div>
            <div class="goods-info">
              <div class="goods-name">{{ plan.goods.product.name }}</div>
              <div class="goods-size">
                <span>规格：1 * {{ plan.goods.product.size }}</span>
              </div>
              <div class="goods-storage">
                <span>储位：{{ plan.goods.default_in_warehouse.storage }}</span>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getStocktakingPlans } from '@/api/stocktaking_plan'
export default {
  name: 'StocktakingPlan',
  components: { myNavBar },
  data() {
    return {
      listQuery: {
        page: 0
      },
      list: [],
      showEmpty: false,
      finished: false,
      loading: false
    }
  },
  created() {
    // 缓存当前页面
    this.$store.commit('setKeepAlive', this.$options.name)
    // 监听回传数据
    this.$eventBus.$on('stocktaking-finished', (res) => {
      const index = this.list.findIndex(plan => plan.goods_id === Number(res.goods_id))
      if (index !== -1) {
        this.list.splice(index, 1)
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path !== '/warehouse-goods-detail' && to.path !== '/stocktaking-plan-history') {
      this.$store.commit('rmKeepAlive', this.$options.name)
      this.$eventBus.$off('stocktaking-finished')
    }

    next()
  },
  methods: {
    getList() {
      this.listQuery.page++
      getStocktakingPlans(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.finished = res.data.list.length < 10
        this.loading = false
        this.showEmpty = this.list.length === 0
      })
    },
    toGoodsWarehouseDetailView(goodsId, planId) {
      this.$router.push({
        path: '/warehouse-goods-detail',
        query: {
          goods_id: goodsId,
          stocktaking_plan_id: planId
        }
      })
    },
    toHistoryView() {
      this.$router.push({
        path: '/stocktaking-plan-history'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "/src/styles/color";
  .list {
    padding: 10px;
  }
  .goods {
    background-color: #fff;
    padding: 10px;
    display: flex;
  }
  .goods-container {
    margin-top: 10px;
  }
  .goods-container:first-child {
    margin-top: 0!important;
  }
  .goods-info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .goods-image {
    width: 80px;
    height: 80px;
    display: block!important;
  }
  .goods-size {
    font-size: 12px;
    color: #989898;
  }
  .goods-storage {
    font-size: 12px;
    color: #989898;
  }
  .tips {
    color: $amountRedColor;
  }
</style>
